import { template as template_1bedbe1b4f244b089e0e5abf0565fd8a } from "@ember/template-compiler";
const SidebarSectionMessage = template_1bedbe1b4f244b089e0e5abf0565fd8a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
