import { template as template_3c9d1670164b463ebbe1361982acaf89 } from "@ember/template-compiler";
const FKText = template_3c9d1670164b463ebbe1361982acaf89(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
