import { template as template_2331cccd9cd24010a1ac588e68fec783 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2331cccd9cd24010a1ac588e68fec783(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
