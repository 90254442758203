import { template as template_d95673180fb2405297b90b577ad76224 } from "@ember/template-compiler";
const WelcomeHeader = template_d95673180fb2405297b90b577ad76224(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
